<template>
    <!-- See: `getFullVh()` -->
    <div style="overflow: hidden; height: 0">
        <div id="measure-vh" style="position: fixed; height: 100vh"></div>
    </div>
    <!-- showTipFeesInfoRecipient -->
    <div id="topdiv" class="modal-overlay" style="width: 100%; ">
        <div class="modal" style="outline:0.0em solid blue; display: table-cell; padding-top: 0vh; padding-bottom: 0vh; background-color:#EDF2F7; box-shadow: 0 0px 0px rgba(0, 0, 0, 0.0);" :style="{ 'height': 98 * this.vhMult + 'vh'}"> 
            <table width=100% border=0 style="margin: auto; padding-left: 0vh; padding-right: 0vh; ">
                <tr>
                    <td>
                        <table width="100%" border=0 style="height: 6vh">
                            <tr>
                                <td width="30%">
                                    <div class=divl><button class="text-only-button cancel" @click="this.goBack()" style="display: block;" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }"><img class="tulogo" valign=middle style="height: 1.8vh; padding-bottom: 0.3vh" alt="back" src="../assets/chevron.png"> Back</button></div>
                                </td>
                                <td width="40%" ><span style="display: block;" :style="{ 'font-size': fontSizeNormalInPXToUse * 1.2 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 1.2 + 'px', }">Fees breakdown</span>
                                </td>
                                <td width="30%">
                                    &nbsp;
                                </td>

                            </tr>
                        </table>
                        
                    </td>
                </tr>
                <tr>
                    <td height=2vh >
                        <table width="100%" border=0 style="height: 2vh">
                            <tr>
                                <td width="100%">
                                    <div class="divl" style="font-weight: 600; padding-left: 2vh; padding-right: 2vh" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">You generously chose to pay {{ this.payment.recipientName }}'s transaction fees so they receive the whole amount of your tip.</div>
                                </td>
                            </tr>
                             <tr v-if="this.payment.oldWay === true">
                                <td width="100%">
                                    <div class="divl" style="color: #718096; padding-left: 2vh; padding-right: 2vh" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.95 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.95 + 'px', }"> <span v-if="this.payment.tipperPaidFees === true">Our own fee is the 'ThankU fee' below, and we pass through transaction charges from Stripe, our payment processor, these are the 'Stripe flat fee' and 'Stripe processing fee'. For more info, see the 'Details of Stripe's published charges' link below.</span></div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table class="userinfoframenowidth" style="display: block; height: auto; padding-top: 2vh; padding-bottom: 2vh; padding-left: 2vh; padding-right: 2vh" border=0>
                            <tr>
                                <td style="">
                                    <div class="table" style="grid-template-columns: auto 1fr auto;">
                                    <div class="lt-blue cell" style="padding-right: 1vh; " :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">{{ this.payment.recipientName }} received </div>
                                    <div class="cell" style="color: #bdc2ca" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">................................................................................................................................................</div>
                                    <div class="cell" style="padding-left: 1vh" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">{{ this.payment.amountChosen }} </div>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="this.payment.oldWay === true">
                                <td style="">
                                    <div class="table" style="grid-template-columns: auto 1fr auto;">
                                    <div class="lt-blue cell" style="padding-right: 1vh; " :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">ThankU fee @ {{ this.payment.tipperCostData.thankUFeesPercent }}% </div>
                                    <div class="cell" style="color: #bdc2ca" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">................................................................................................................................................</div>
                                    <div class="cell" style="padding-left: 1vh" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">{{ this.payment.tipperCostData.thankUFees }} </div>
                                    </div>
                                </td>
                            </tr>
                            <tr v-show="this.payment.tipperPaidFees === true && this.payment.oldWay === true">
                                <td style="">
                                    <div class="table" style="grid-template-columns: auto 1fr auto;">
                                    <div class="lt-blue cell" style="padding-right: 1vh; " :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">Stripe flat fee </div>
                                    <div class="cell" style="color: #bdc2ca" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">................................................................................................................................................</div>
                                    <div class="cell" style="padding-left: 1vh" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">{{ this.payment.transactionFeeOnTopAmount }} </div>
                                    </div>
                                </td>
                            </tr>
                            <tr v-show="this.payment.tipperPaidFees === true && this.payment.oldWay === true">
                                <td style="">
                                    <div class="table" style="grid-template-columns: auto 1fr auto;">
                                    <div class="lt-blue cell" style="padding-right: 1vh; " :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">Stripe processing fee @ {{ this.payment.transactionFeePlatformPercentReceipt }}%</div>
                                    <div class="cell" style="color: #bdc2ca" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">................................................................................................................................................</div>
                                    <div class="cell" style="padding-left: 1vh" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">{{ this.payment.transactionFeePlatformAmountReceipt }} </div>
                                    </div>
                                </td>
                            </tr>
                            <!-- NEW WAY -->
                            <tr v-if="this.payment.oldWay === false">
                                <td style="">
                                    <div class="table" style="grid-template-columns: auto 1fr auto;">
                                    <div class="lt-blue cell" style="padding-right: 1vh; " :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">Percentage fee @ {{ this.payment.tipperCostData.percentageFeePercentage }}% </div>
                                    <div class="cell" style="color: #bdc2ca" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">................................................................................................................................................</div>
                                    <div class="cell" style="padding-left: 1vh" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">{{ this.payment.tipperCostData.percentageFee }} </div>
                                    </div>
                                </td>
                            </tr>
                            <tr v-show="this.payment.oldWay === false">
                                <td style="">
                                    <div class="table" style="grid-template-columns: auto 1fr auto;">
                                    <div class="lt-blue cell" style="padding-right: 1vh; " :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">Flat fee </div>
                                    <div class="cell" style="color: #bdc2ca" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">................................................................................................................................................</div>
                                    <div class="cell" style="padding-left: 1vh" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">{{ this.payment.tipperCostData.flatFee }} </div>
                                    </div>
                                </td>
                            </tr>
                            <!-- FINISH NEW WAY -->

                            <!-- <tr>
                                <td>
                                    <div class="table">
                                    <div class="lt-blue cell" style="font-weight: 600" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">You paid
                                        </div>
                                    <div class="cell" style="color: #FFFFFF" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">................................................................................................................................................</div>
                                    <div class="cell" style="font-weight: 600" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }"><span v-if="this.payment.tipperCostData.tipperCostCurrEquiv !== ''"  style="color: #718096" >{{this.payment.tipperCostData.tipperCostCurrEquiv}}&nbsp;&nbsp;</span>{{ this.payment.tipperCostData.tipperCost }} </div>
                                    </div>
                                </td>
                            </tr> -->
                            <tr>
                                <td>
                                    <table width="100%" cellpadding=0 cellspacing=0 style="height: 4vh">
                                        <tr>
                                            <td width="30%" class="divl">
                                                <div class="lt-blue" style="font-weight: 600" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">You paid
                                                </div>
                                            </td>
                                            <td width="70%" class="divr">
                                                <div style="font-weight: 600" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }"><span v-if="this.payment.tipperCostData.tipperCostCurrEquiv !== ''"  style="color: #718096" >{{this.payment.tipperCostData.tipperCostCurrEquiv}}&nbsp;&nbsp;</span>{{ this.payment.tipperCostData.tipperCost }} </div>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td height=2vh style="padding-top: 0vh" >

                                    <div class="divl" style="color: #718096; padding-left: 2vh" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.95 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.95 + 'px', }">{{ this.payment.tipDate }}</div>
                                        
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td height=2vh style="padding-top: 1vh" >
                        <table width="100%" border=0 style="height: 2vh">
                            <tr>
                                <td width="100%">
                                    <div class="divc" style="color: #718096; padding-left: 2vh" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">Transaction ID: {{ this.payment.transactionId}} </div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr class="userinfoframe" style="" >
                    <td style="padding-top: 0vh; " >
                        <div class="divc" style="color: #718096; padding-left: 2vh; padding-right: 2vh;" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.8 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.8 + 'px', }">

                            Details of Stripe's published charges: <a :href="'https://stripe.com/'
                            + this.stripeCountryCodeToUse  + 'pricing'" target="_blank">https://stripe.com/{{ this.payment.stripeCountryCodeToUse }}pricing</a>.
                            
                            <!-- //and https://stripe.com/" + {{ this.payment.stripeCountryCode }} + "/connect/pricing (custom connected accounts)<br><br>"; -->

                        </div>
                                
                    </td>
                </tr>

            </table>       
      </div>
    </div>
</template>

<script>

const Parse = require('parse/node');
import shared from '../shared.js';

export default {
    name: 'TipFeesInfoScreen',
    inject: ['globalPushForwardInterval', 'devEnv', 'appId', 'appJSKey'] ,
    data () {
        return {
            fontSizeNormal: 2.5, // vh
            fontSizeNormalInPXToUse: "",
            fontSizeNormalLineHeight: 3.3,// vh
            fontSizeNormalInPXLineHeightToUse: "",
            fontSizeRecord: 2.5, // vh
            fontSizeRecordInPXToUse: "",
            fontSizeRecordLineHeight: 2.7,// vh
            fontSizeRecordInPXLineHeightToUse: "",
            payment: {},
            vhMult: 1,
            stripeCountryCodeToUse: "",
        }
    },
    props:{
        userData: shared.userDataStruct(),
    },
    methods: {
        doFontSizeComputed () {
            this.fontSizeNormalInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormal, 16);
            this.fontSizeNormalInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormalLineHeight, 20);
            this.fontSizeRecordInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeRecord, 16);
            this.fontSizeRecordInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeRecordLineHeight, 17);
        },
        goBack(){
            this.userData.nextScreen = "showTipInfoTipper";
            this.userData.selectedPaymentObject = this.payment;
            this.$emit("next-action", this.userData);
        },        
    },
    created(){
        this.payment = this.userData.selectedPaymentObject;

        console.log("this.payment on TipFeesInfoTipper:: " + JSON.stringify(this.payment, null, 2));
        this.stripeCountryCodeToUse = this.payment.stripeCountryCode !== "us" ? this.payment.stripeCountryCode + "/": "";
    },
    mounted(){
        this.doFontSizeComputed();
        this.vhMult = shared.setScreenHeightMultiplier();
        shared.saveToUserPath(this.devEnv, "Tip Fees info " + new Date().toISOString(), this.globalPushForwardInterval);
    }
}
</script>

<style scoped>
.table {
  height:4vh;
  display: grid;
  /* outline:0.01em solid black; */
  grid-template-columns: auto auto auto;
}

.cell {
  text-overflow: clip;
  /* color: #bdc2ca; */
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  justify-content: left;
  align-items: center;
  /* outline:0.01em solid black; */
  
}

</style>