<template>
    <!-- See: `getFullVh()` -->
    <div style="overflow: hidden; height: 0">
        <div id="measure-vh" style="position: fixed; height: 100vh"></div>
    </div>
    <!-- showPopUpOk --><transition name="fade">
    <div v-show="showPopUpOk" class="modal-overlayfrombottomblur" style="z-index: 999;">
      <div class="modal" style="padding-top: 3vh; padding-bottom: 3vh; min-height: 40vh; width: 70%"> 
        <!-- put in table for dev speed need to learn more about flex -->
        <table border=0 width=80%> 
          <tr>
            <td>
              <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizeTitle + 'vh', 'line-height': this.fontSizeTitleLineHeight + 'vh', }">                 
              {{ this.popUpMsgTitle }}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizePopUpMsg + 'vh', 'line-height': this.fontSizePopUpMsgLineHeight + 'vh', 'padding-top': '1vh', }">                 
              {{ this.popUpMsgBody }}
              </div>
            </td>
          </tr>
          <tr v-show="!this.suppressOk">
            <td style="height: 10vh">
              <div style="padding-top: 2vh"><button class="widebtn" ref="show-popup-ok" style="width: 50%; height: 6vh; " @click="this.doPopUpOk();">Ok</button></div>
            </td>
          </tr>
          
        </table>       
      </div>
    </div>
    </transition>
    <!-- showChoosePaymentMethod --><transition name="fade">
    <div v-show="showChoosePaymentMethods===true" id="topdiv" class="modal-overlay" style="width: 100%">
        <div class="modal" style="outline:0.0em solid blue; display: table-cell; padding-top: 0vh; padding-bottom: 0vh; background-color:#EDF2F7; box-shadow: 0 0px 0px rgba(0, 0, 0, 0.0);" :style="{ 'height': 98 * this.vhMult + 'vh'}"> 

        <table cellspacing="0" cellpadding="0" width=100% border=0 style="padding-left: 2%; padding-right: 2%; ">
            <tr>
                <td>
                    <table width="100%" border=0 style="height: 6vh">
                        <tr>
                            <td width="20%">
                                <div class=divl><button class="text-only-button cancel" @click="this.goBack()" style="display: block;" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }"><img class="tulogo" valign=middle style="height: 1.8vh; padding-bottom: 0.3vh" alt="back" src="../assets/chevron.png"> Back</button></div>
                            </td>
                            <td width="60%">
                                Payment methods
                            </td>
                            <td width="20%">
                            
                            </td>

                        </tr>
                    </table>
                    
                </td>
            </tr>
           
            <tr><td height=2vh style="height:1vh; line-height:2vh" >&nbsp;</td></tr>           
            <tr>
                <td height=2vh align=center>
                    <table width="70%" border=0 style="height: 2vh">
                        <tr>
                            <td width="85%">
                                <div class="divc" style="color: #1A202C; " :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">Please choose your payment method</div>
                            </td>
                        </tr>
                    </table>
                    
                </td>
            </tr>
            <tr><td height=2vh style="height:1vh; line-height:2vh" >&nbsp;</td></tr>           
            <tr v-show="this.readyToDisplay===true">
                <td>
                    <table width=100% border=0 cellspacing="0" cellpadding="0" >
                        <tr v-show="this.showApplePay === true" style="height: 8vh;">
                            <td width=4%>
                                &nbsp;
                            </td>
                            <td width=77% >
                                <img class="tulogo" align=left valign=middle style="height: 6.6vh; padding-bottom: 0.3vh" alt="Apple Pay" src="../assets/applewide.png">
                                <table align=center width=35% border=0 cellspacing="0" cellpadding="0" >
                                    <tr>
                                        <td style="height: 6vh;" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.75 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.7 + 'px', }">{{ this.applePayMessage }}</td>
                                    </tr>
                                    
                                </table>
                            </td>
                            
                            <td width=15% align=right>
                                <!-- Rounded switch -->
                                <label class="switch">
                                <input type="checkbox" @click="toggleSelection1();" ref="switch1">
                                <span class="slider round"></span>
                                </label>
                            </td>
                            <td width=4%>
                                &nbsp;
                            </td>
                        </tr>
                        <tr v-show="this.showGooglePay===true" style="height: 8vh;">
                            <td width=4%>
                                &nbsp;
                            </td>
                            <td width=65%>
                                <img class="tulogo" align=left valign=middle style="height: 6.6vh; padding-bottom: 0.3vh" alt="Google Pay" src="../assets/googlewide.png">
                            </td>
                            <td width=15% align=right>
                                <!-- Rounded switch -->
                                <label class="switch">
                                <input type="checkbox" @click="toggleSelection2();" ref="switch2">
                                <span class="slider round"></span>
                                </label>
                            </td>
                            <td width=4%>
                                &nbsp;
                            </td>
                        </tr>
                        <tr style="height: 8vh;">
                            <td width=4%>
                                &nbsp;
                            </td>
                            <td width=65%>
                                <img class="tulogo" align=left valign=middle style="height: 6.1vh; padding-bottom: 0.3vh; padding-left: 0.0vh" alt="payment card" src="../assets/cardiconnarrowblue.png">
                                <table v-show="this.user.hasSavedPaymentSource===true" align=center width=35% border=0 cellspacing="0" cellpadding="0" >
                                    <tr>
                                        <td :style="{ 'font-size': fontSizeNormalInPXToUse * 0.75 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">{{ this.user.cardBrand}} *{{ this.user.last4Digits }}</td>
                                    </tr>
                                    <tr>
                                        <td><button class="widebtn" style="font-size: 14px" @click="this.doCardInput()">Change</button></td>
                                    </tr>
                                </table>
                            </td>
                            <td width=15% align=right>
                                <!-- Rounded switch -->
                                <label class="switch">
                                <input type="checkbox" @click="toggleSelection3();" ref="switch3">
                                <span class="slider round"></span>
                                </label>
                            </td>
                            <td width=4%>
                                &nbsp;
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
          
            <tr style="display: none;">
                <td style="height: 12vh">
                    <div><button class="widebtn" style="width: 70%; height: 5vh;" @click="this.doUpdateDBDisplayName();">Update</button></div>
                </td>
            </tr>
            <tr>
                <td align=center style="padding-top: 3vh">
                    <div style="color: #718096; width: 90%;" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.9 + 'px', }">{{ this.PaymentMethodsMsg }}</div>
                </td>
            </tr>
        
 
          
            
            <tr><td height=70vh style="height:1vh; line-height:2vh" >&nbsp;</td></tr>  
        </table> 
        
      </div>
    </div>
    </transition>
    <!-- showCardInput --><transition name="screenslideup">
      <div v-show="showCardInput" class="modal-overlay">
        <div class="modal" style="padding-top: 2vh; padding-bottom: 2vh"> 

            <table width=95%>
            <tr>
                <td>
                <table width="100%" border=0 style="height: 6vh">
                        <tr>
                            <td width="20%">
                                <div class=divl><button class="text-only-button cancel" @click="this.goBack()" style="display: block;" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">Cancel</button></div>
                            </td>
                            <td width="60%">
                                Card details
                            </td>
                            <td width="20%">
                            
                            </td>

                        </tr>
                    </table>
                
                </td>
            </tr>
            
            <tr>
                <td height=2vh align=center>
                    <table width="70%" border=0 style="height: 2vh">
                        <tr>
                            <td width="85%">
                                <div class="divc" style="color: #1A202C; " :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">Securely register your card details for future tipping</div>
                            </td>
                        </tr>
                    </table>
                    
                </td>
            </tr>
            
            <tr>
                <td>
                <!-- <form id="payment-form" class="paymentform"> -->
                <div id="card-element" style="padding-top: 3vh; height: 5vh; margin: auto; width:90%; outline:0.0em solid black">
                    <!-- Elements will create input elements here -->
                </div>
                <!-- We'll put the error messages in this element -->
                <div id="card-errors" role="alert" style="font-size:0px"></div>
                <!-- </form>  -->
                <p></p>
                <button class="widebtn" style="height: 7vh" @click="this.cardInputConfirm();" >Register</button>
                
                <p></p>
            
                <div class="lt-blue notes-text">
                    <span class="primary-general-text" style="font-size:10px; line-height: 12px; padding-top: 0.1px">ThankU does not store your payment details, only a secure digital token from our payment processor, like Apple Pay and Google Pay</span>
                </div>
                <!-- <div style="padding-top: 3vh; height: 4vh;">
                    <a href="https://stripe.com/customers" target="_blank"><img alt="powered by Stripe" class="poweredbystripe"  src="../assets/poweredbystripe.png"></a>
                </div> -->
                </td>
            </tr>
            </table>       
        </div>
      </div>
    </transition>
    
</template>

<script>

const Parse = require('parse/node');

import shared from '../shared.js';
import {loadStripe} from '@stripe/stripe-js/pure';

var stripe; 

export default {
    name: 'AccountEditScreen',
    inject: ['globalPushForwardInterval', 'devEnv', 'appId', 'appJSKey'] ,
    props:  {
        userData: shared.userDataStruct(),
    },
    data () {
        return {
            fontSizeNormal: 3.0, // vh
            fontSizeNormalInPXToUse: "",
            fontSizeNormalLineHeight: 3.6,// vh
            fontSizeNormalInPXLineHeightToUse: "",
            fontSizeRecord: 3.0, // vh
            fontSizeRecordInPXToUse: "",
            fontSizeRecordLineHeight: 3.0,// vh
            fontSizeRecordInPXLineHeightToUse: "",
            user: {},
            userImageDataURL: "",
            showPopUpTwoOptions: false,
            PopUpTwoOptionsTitle: "",
            PopUpTwoOptionsMessage: "",  
            option1ButtonText: "",
            option2ButtonText: "",
            showRecipientEditScreen: true,
            showChoosePaymentMethods: true,
            showEmailEdit: false,
            showPasswordEdit: false,
            PaymentMethodsMsg: "",
            emailMsg: "",
            pwMsg: "",
            showPopUpOk: false,
            popUpMsgTitle: "",
            popUpMsgBody: "",
            showPhotoEdit: false,
            dataURL: "",
            applePayAvailable: false,
            googlePayAvailable: false,
            showApplePay: false,
            showGooglePay: false,
            applePayMessage: "",
            readyToDisplay: false,
            showCardInput: false,
            willHideCardInput: false,
            
            vhMult: 1,
            
            stripePublishableKey: process.env.VUE_APP_STRIPE_ENV === "dev" ? process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY_TEST : process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY_LIVE,
            
        }
    },
    computed: {
        containerStyles: function () {
            return {
                width: this.width,
                height: this.height
            };
        }
    },
    methods: {
        doFontSizeComputed () {
            this.fontSizeNormalInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormal, 16);
            this.fontSizeNormalInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormalLineHeight, 20);
            this.fontSizeRecordInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeRecord, 16);
            this.fontSizeRecordInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeRecordLineHeight, 17);
        },
        
        goBack(){
            if (this.showRecipientEditScreen === false){
                this.showChoosePaymentMethods = false;
                this.showEmailEdit = false;
                this.showPasswordEdit = false;
                this.showPhotoEdit = false;
                this.showPopUpOk = false;
                this.showRecipientEditScreen = true;
            } else {
                this.doShowAccountScreen();
            }
        },
        async toggleSelection1(){
            // console.log("toggling");
            if (this.$refs.switch1.checked === true){
                // console.log("1");
                this.$refs.switch2.checked = false;
                this.$refs.switch3.checked = false;
                this.user.paymentMethod = "AP";
                // this.userData.paymentMethod = "AP";
                
                try {
                    await Parse.Cloud.run("saveUserFromWebApp", {userObjectId: this.user.userObjectId, "PaymentMethod" : "AP"}); 
                } catch (e) {
                    this.popUpMsgTitle = "Sorry";
                    this.popUpMsgBody = e.message;
                    this.showPopUpOk = true;
                } 
            } 
        },
        async toggleSelection2(){
            if (this.$refs.switch2.checked === true){
                // console.log("2");
                this.$refs.switch1.checked = false;
                this.$refs.switch3.checked = false;
                this.user.paymentMethod = "GP";
                // this.userData.paymentMethod = "GP";
                try {
                    await Parse.Cloud.run("saveUserFromWebApp", {userObjectId: this.user.userObjectId, "PaymentMethod" : "GP"}); 
                } catch (e) {
                    this.popUpMsgTitle = "Sorry";
                    this.popUpMsgBody = e.message;
                    this.showPopUpOk = true;
                } 
            } 
        },
        async toggleSelection3(){
            if (this.$refs.switch3.checked === true){
                // console.log("3");
                this.$refs.switch1.checked = false;
                this.$refs.switch2.checked = false;
                this.user.paymentMethod = "STCC";
                // this.userData.paymentMethod = "STCC";
                try {
                    await Parse.Cloud.run("saveUserFromWebApp", {userObjectId: this.user.userObjectId, "PaymentMethod" : "STCC"}); 
                    if (this.user.hasSavedPaymentSource !== true) {
                        this.doCardInput();
                    } else {
                        //do nothing
                    }
                } catch (e) {
                    this.popUpMsgTitle = "Sorry";
                    this.popUpMsgBody = e.message;
                    this.showPopUpOk = true;
                } 
                
            } 
        },
        doCardInput(){
            var elements = stripe.elements();
            var style = {    
                base: {
                iconColor: '#c4f0ff',
                color: '#000012',
                fontWeight: '500',
                fontFamily: '"LT", Helvetica, Arial',
                fontSize: '14px',
                fontSmoothing: 'antialiased',
                ':-webkit-autofill': {
                color: '#000012',
                },
                '::placeholder': {
                    color: '#87BBFD',
                },
                },
                invalid: {
                iconColor: '#FFC7EE',
                color: '#FFC7EE',
                },
            };

            var card = elements.create("card", { style: style, hidePostalCode: true });
            card.mount("#card-element");

            this.card = card;

            card.on('change', async ev =>  {

                // console.log("HERE IS THE ERROR " + JSON.stringify(ev, null, 2));

                // var displayError = document.getElementById('card-errors');
                if (ev.error) {
                // displayError.textContent = event.error.message;
                // this.popUpMsgBody = JSON.stringify(event.error, null, 2);
                // this.showPopUpOk = true;
                // console.log("card problem");
                // console.log(JSON.stringify(ev.error, null, 2));
                // shared.saveToUserPath(this.devEnv,  "Tipper " + this.tipper.objectId + " error on user card input: " +  ev.error.message + "" + new Date().toISOString(), this.globalPushForwardInterval);
                } else {
                // console.log("all fine");
                // console.log(JSON.stringify(ev, null, 2));
                // shared.saveToUserPath(this.devEnv,  "Tipper " + this.tipper.objectId + " now fine on user card input: " + new Date().toISOString(), this.globalPushForwardInterval);
                // displayError.textContent = 'SUCCESS' + JSON.stringify(event, null, 2);
                }
            });
            this.willHideCardInput = false;
            this.showCardInput = true;
        },
        async cardInputConfirm(){

            // shared.saveToUserPath(this.devEnv,  "Account holder " + this.tipper.objectId + " confirmed on card input dialog " + new Date().toISOString() + " button click num: " + this.buttonPressNum, this.globalPushForwardInterval);

            const sourceUsage = 'reusable';

            try {
                let source = await stripe.createSource(this.card, {
                    type: 'card',
                    usage: sourceUsage,
                });

                if (source.error) {

                    // console.log("SOURCE ERROR:::: " + JSON.stringify(source.error));

                    this.popUpMsgTitle = "Sorry";
                    this.popUpMsgBody = source.error.message;
                    this.showPopUpOk = true;

                    shared.saveToUserPath(this.devEnv,  "User " + this.user.userObjectId + " error on ACCOUNT user card input: creation of source: " +  source.error.message + "" + new Date().toISOString(), this.globalPushForwardInterval);
                    
                    // don't log this back to the cloud as it will mess with the client side error messaging

                    return;
                
                } else {
                    // console.log("SOURCE IS FINE!!");
                }

                // console.log("SOURCE::: " + JSON.stringify(source, null, 2));

                if (source) {

                    if (sourceUsage === 'reusable'){
                        // OK BIG NB!!!!!!! the PROBLEM with this is IF the source/customer CANNOT be created on the backend becuase of some payment failure, a decline which might be CVC or postcode, then the front end THINKS hasSavedPaymentSource is TRUE when it isn't, and therefore the follow on call to the backend doesnt have any source information AND the user record on the backend hasn't any stored source/customer ids either
                        this.user.hasSavedPaymentSource = true; // set this as a session flag even though it will be saved for next time in the backend
                        this.user.last4Digits = source.source.card.last4;// set this as a session value even though it will be saved for next time in the backend
                        this.user.cardBrand = source.source.card.brand;// set this as a session value even though it will be saved for next time in the backend

                        if (this.user.cardBrand === "American Express") {
                            this.user.cardBrand = "Amex";
                        } else if (this.user.cardBrand === "Mastercard") {
                            this.user.cardBrand = "M'card";
                        }
                    }

                    let params = {
                        userObjectId: this.user.userObjectId,
                        sourceId: source.source.id,
                        country: source.source.card.country,
                        last4Digits: source.source.card.last4,
                        cardBrand: source.source.card.brand,
                    }
 
                    const result = await Parse.Cloud.run("runConnectSourceToCustomer", params); 

                    // console.log("result: " + JSON.stringify(result, null,2));

                    if (result.status === "ok") {
                        this.popUpMsgTitle = "Great!";
                        this.popUpMsgBody = "Your card has been successfully registered for future use";
                        this.showPopUpOk = true;
                        this.willHideCardInput = true;
                        return;
                    } else {
                        this.popUpMsgTitle = "Sorry";
                        this.popUpMsgBody = "There was a problem registering your card - please try again or contact hello@thanku.app";
                        this.showPopUpOk = true;
                        this.willHideCardInput = true;
                        return;
                    }
                
                }
                
            } catch (e) {
                // console.log("cardInputConfirm error: " + JSON.stringify(e, null, 2));

                this.popUpMsgTitle = "Sorry";
                this.popUpMsgBody = e.message;
                this.showPopUpOk = true;
                return;
            }

        },
        async checkAppleAndGooglePayStatus(){
            var paymentRequest = stripe.paymentRequest({
                country: 'GB', 
                currency: 'gbp',
                total: {
                    label: 'test',
                    amount: 100,
                }
            });

            paymentRequest.canMakePayment().then(result => {

                // console.log("paymentRequest result::: " + JSON.stringify(result, null, 2));

                if (result) {
                    if (result.applePay) { 
                        this.applePayAvailable = true;
                        this.showApplePay = true;
                        this.PaymentMethodsMsg = "Apple Pay is the easiest and most secure way to pay in ThankU as it's already set up and you don't need to do anything. Simply tip, and confirm with Touch ID/Face ID. Alternatively, register your card details just once here, they are converted into a secure digital token so you don't have to put them in again every time you tip.";
                    }

                    if (result.googlePay) {
                        this.googlePayAvailable = true;
                        this.showGooglePay = true;
                        this.PaymentMethodsMsg = "Google Pay is the easiest and most secure way to pay in ThankU as it's already set up and you don't need to do anything. Alternatively, register your card details just once here, they are converted into a secure digital token so you don't have to put them in again every time you tip.";
                    }
                
                } else {
                    if (shared.getDeviceFullObject().deviceType === "iPhone"){
                        this.showApplePay = true;
                        this.ApplePayMessage = "Not yet set up on this iPhone";
                        this.PaymentMethodsMsg = "To set up Apple Pay on your iPhone, go to your iPhone's settings and look for Wallet & Apple Pay. Alternatively, register your card details just once here, they are converted into a secure digital token so you don't have to put them in again every time you tip.";
                    } else {
                        // just for dev
                        // this.PaymentMethodsMsg = "Apple Pay is the easiest and most secure way to pay in ThankU. Simply tip, and confirm with Touch ID/Face ID. Alternatively, register your card details just once here, they are converted into a secure digital token so you don't have to put them in again every time you tip"
                    }
                }

                this.readyToDisplay = true;
            });
        },

        async assignModuleVars() {


            // stripe = await Stripe(this.stripePublishableKey); this was the old way of doing it where we used <script on index.html
            stripe = await loadStripe(this.stripePublishableKey); // now we just bring in when needed
            // console.log("STRIPE KEY:::" + this.stripePublishableKey); // /console.log(stripe);
            // console.log("process.env.VUE_APP_STRIPE_ENV === dev :::: " + (process.env.VUE_APP_STRIPE_ENV === "dev"));
            // console.log(process.env.VUE_APP_STRIPE_ENV);
            await this.checkAppleAndGooglePayStatus();
            
            return;

        },

        doEditDisplayname(){
            this.showRecipientEditScreen = false;
            this.showChoosePaymentMethods = true;
        },
       
        async doUpdateDBDisplayName(){
            
            const displayname = this.$refs['displayname-edit'].value;

            if (displayname === "" || displayname.length < 2 ){
                this.showPopUpOk = true;
                this.popUpMsgTitle = "Oops";     
                this.popUpMsgBody = "Your display name must be at least 2 characters please";
            } else {
                var params = {
                    userObjectId: this.user.userObjectId,
                    displayname: displayname 
                };
                await Parse.Cloud.run("saveUserFromWebApp", params);
                this.user.displayname = displayname;
                this.goBack();
            }
        },
        doPopUpOk(){
            this.showPopUpOk = false;

            if (this.willHideCardInput === true) {
                this.showCardInput = false;
            }
        },
        doShowAccountScreen(){
            // console.log("leaving accountPaymentMethod screen");
            // this.userData = this.user;
            this.userData.nextScreen = "showAccountScreenFromPaymentMethodScreen"
            this.$emit("next-action", this.userData);
        },
        setPaymentSelectors(){

            // console.log("this.userpaymentMethod: " + this.user.paymentMethod);
            // comes after Stripe is mounted
            if (this.user.paymentMethod === "AP") {
                this.$refs.switch1.checked = true;
            } else if (this.user.paymentMethod === "GP") {
                this.$refs.switch2.checked = true;
            } else if (this.user.paymentMethod === "STCC")  {
                if (this.user.hasSavedPaymentSource === true) {
                    this.$refs.switch3.checked = true;
                }
            } else {
                if (this.applePayAvailable === true) {
                    // default to Apple Pay
                    this.$refs.switch1.checked = true;
                } else if (this.googlePayAvailable === true) {
                    // default to Google Pay
                    this.$refs.switch2.checked = true;
                }
            }
        },
        
    },
    created(){

        // console.log("creating AccountPaymentMethod");
        // console.log("this is USERDATA on AccountPaymentMethod: " + JSON.stringify(this.userData, null, 2));
        Parse.serverURL = 'https://parseapi.back4app.com/'; Parse.initialize(this.appId, this.appJSKey); (this.appId, this.appJSKey);
        this.user.displayname = this.userData.displayname; //window.localStorage.getItem("tudis");
        this.user.email = this.userData.email;
        this.user.userObjectId = this.userData.userObjectId;
        // console.log("I'm created with user: " + this.user.userObjectId);
        this.userImageDataURL = this.userData.userThumbnail;

        this.user.paymentMethod = this.userData.paymentMethod;
        this.user.last4Digits = this.userData.paymentLast4;
        this.user.cardBrand = this.userData.paymentCardBrand;

        if (this.user.cardBrand === "American Express") {
            this.user.cardBrand = "Amex";
        } else if (this.user.cardBrand === "Mastercard") {
            this.user.cardBrand = "M'card";
        }

        if (this.user.last4Digits !== undefined) {
            this.user.hasSavedPaymentSource = true;
        }

        

        // this.userImageDataURL = "FOREST";

        // if (this.userData.isRecipient === false) {
        //     this.PaymentMethodsMsg = "Apple Pay is the easiest and most secure way to pay in ThankU. Simply tip, and confirm with Touch ID/Face ID.";
        // } else {
        //     this.PaymentMethodsMsg = "Apple Pay is the easiest and most secure way to pay in ThankU. Simply tip, and confirm with Touch ID/Face ID.";
        // }

        this.assignModuleVars();

        
    },
    mounted(){
        // console.log("mounting AccountPaymentMethod");
        this.setPaymentSelectors();
        this.doFontSizeComputed();
        this.vhMult = shared.setScreenHeightMultiplier();
        shared.saveToUserPath(this.devEnv, "Viewed recipient account screen " + new Date().toISOString(), this.globalPushForwardInterval);


    }
}
</script>

<style scoped>

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 8vh;
  height: 4vh;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 3vh;
  width: 3vh;
  left: 4px;
  bottom: 0.5vh;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #68D391;
}

input:focus + .slider {
  box-shadow: 0 0 1px #68D391;
}

input:checked + .slider:before {
  -webkit-transform: translateX(3.8vh);
  -ms-transform: translateX(3.8vh);
  transform: translateX(3.8vh);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

</style>
