<template>
    <!-- See: `getFullVh()` -->
    <div style="overflow: hidden; height: 0">
        <div id="measure-vh" style="position: fixed; height: 100vh"></div>
    </div>
    <!-- showTipInfoRecipient -->
    <div id="topdiv" class="modal-overlay" style="width: 100%; ">
        <div class="modal" style="outline:0.0em solid blue; display: table-cell; padding-top: 0vh; padding-bottom: 0vh; background-color:#EDF2F7; box-shadow: 0 0px 0px rgba(0, 0, 0, 0.0);" :style="{ 'height': 98 * this.vhMult + 'vh'}"> 

            <table width=100% border=0 style="margin: auto; padding-left: 0vh; padding-right: 0vh; ">
                <tr>
                    <td>
                        <table width="100%" border=0 style="height: 6vh">
                            <tr>
                                <td width="30%">
                                    <div class=divl><button class="text-only-button cancel" @click="this.goBack()" style="display: block;" :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }"><img class="tulogo" valign=middle style="height: 1.8vh; padding-bottom: 0.3vh" alt="back" src="../assets/chevron.png"> Back</button></div>
                                </td>
                                <td width="40%" ><span style="display: block;" :style="{ 'font-size': fontSizeNormalInPXToUse * 1.2 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 1.2 + 'px', }">Tip info</span>
                                </td>
                                <td width="30%">
                                    &nbsp;
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td height=2vh >
                        <table width="100%" border=0 style="height: 2vh">
                            <tr>
                                <td width="100%">
                                    <div class="divl" style="color: #718096; font-weight: 600; padding-left: 2vh" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.95 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">TIP BREAKDOWN</div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                
                <tr>
                    <td>
                        <table class="userinfoframenowidth" style="display: block; height: auto; padding-top: 2vh; padding-bottom: 2vh; padding-left: 2vh; padding-right: 2vh" border=0>
                            <tr>
                                <td style="">
                                    <div class="table" style="grid-template-columns: auto 1fr auto;">
                                    <div class="lt-blue cell" style="padding-right: 1vh; " :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">{{ this.payment.tipperName }} tipped you </div>
                                    <div class="cell" style="color: #bdc2ca" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">................................................................................................................................................</div>
                                    <div class="cell" style="padding-left: 1vh" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">{{ this.payment.amountChosen }} </div>
                                    </div>
                                </td>
                            </tr>
                            <tr v-show="this.totalRecipientFeesMath > 0">
                                <td>
                                    <div class="table">
                                  
                                    <div v-show="this.payment.recipientPoolId === '547'" class="lt-blue cell" style="padding-right: 1vh;" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">Transaction fees
                                    </div>
                                    <div v-show="this.payment.recipientPoolId !== '547'" @click="doShowTipFeesInfo();" class="lt-blue cell" style="padding-right: 1vh; cursor: pointer" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">Fees&nbsp;<img class="tulogo" valign=middle style="height: 2.7vh; padding-bottom: 0.3vh" alt="back" src="../assets/helptrans.png">
                                    </div>
                                    <div class="cell" style="color: #bdc2ca" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">................................................................................................................................................</div>
                                    <div class="cell" style="padding-left: 1vh" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">{{ this.payment.recipientFees }} </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="table">
                                    <div class="lt-blue cell" style="font-weight: 600" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">You received
                                        </div>
                                    <div class="cell" style="color: #FFFFFF" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">................................................................................................................................................</div>
                                    <div class="cell" style="font-weight: 600" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">{{ this.payment.amount }} </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td height=2vh style="padding-top: 0vh" >
                                    <div class="divl" style="color: #718096; padding-left: 2vh" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.95 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse * 0.95 + 'px', }">{{ this.payment.tipDate }}</div>
                                        
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td height=2vh style="padding-top: 3vh" >
                        <table width="100%" border=0 style="height: 2vh">
                            <tr>
                                <td width="100%">
                                    <div class="divl" style="color: #718096; font-weight: 600; padding-left: 2vh" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.95 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">{{ this.payment.tipperNameUppercase }} HISTORY</div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table class="userinfoframenowidth" style="display: block; height: auto; padding-top: 2vh; padding-bottom: 2vh; padding-left: 2vh; padding-right: 2vh" border=0>
                            <tr>
                                <td style="">
                                    <div class="table">
                                    <div class="lt-blue cell" style="padding-right: 1vh" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">Number of tips</div>
                                    <div class="cell" style="color: #FFFFFF"  :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">................................................................................................................................................</div>
                                    <div class="cell" style="padding-left: 1vh" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">{{ this.payment.numberOfTips }} </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="table">
                                    <div class="lt-blue cell" style="padding-right: 1vh;" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">Average tip
                                        </div>
                                    <div class="cell" style="color: #FFFFFF"  :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">................................................................................................................................................</div>
                                    <div class="cell" style="padding-left: 1vh" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">{{ this.payment.averageTip }} </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                
                                                            <div class="table">
                                    <div class="lt-blue cell" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">Total tips given
                                        </div>
                                    <div class="cell" style="color: #FFFFFF" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">................................................................................................................................................</div>
                                    <div class="cell" style="font-weight: 600" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">{{ this.payment.totalTipsGiven }} </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td height=2vh style="padding-top: 5vh" >
                        <table width="100%" border=0 style="height: 2vh">
                            <tr>
                                <td width="100%">
                                    <div class="divc" style="color: #718096; padding-left: 2vh; padding-right: 2vh" :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">Transaction ID: {{ this.payment.transactionId}} </div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>       
      </div>
    </div>
</template>

<script>

const Parse = require('parse/node');
import shared from '../shared.js';

export default {
    name: 'TipInfoScreen',
    inject: ['globalPushForwardInterval', 'devEnv', 'appId', 'appJSKey'] ,
    props:  {
        userData: shared.userDataStruct(),
    },
    data () {
        return {
            fontSizeNormal: 2.5, // vh
            fontSizeNormalInPXToUse: "",
            fontSizeNormalLineHeight: 3.3,// vh
            fontSizeNormalInPXLineHeightToUse: "",
            fontSizeRecord: 2.5, // vh
            fontSizeRecordInPXToUse: "",
            fontSizeRecordLineHeight: 2.7,// vh
            fontSizeRecordInPXLineHeightToUse: "",
            payment: {
                amount: "",
                amountChosen: "",
                recipientFees: "",
                tipperPaidFees: false,
                tipperName: "",
                tipperNameUppercase: "",
                tipDate: "",
                numberOfTips: "",
                averageTip: "",
                totalTipsGiven: "",
                transactionId: "",
                thankUFeeTransferred: "",
                transactionFeeThankUPercentReceipt: "",
                transactionFeePlatformPercentReceipt: "",
                transactionFeePlatformAmountReceipt: "",
                transactionFeeOnTopAmount: "",
                stripeMonthlyActiveUserFee: "",
                stripePayoutVolumeFee: "",
                stripePayoutFlatFee: "",
                stripeCountryCode: "",
            },
            vhMult: 1,    
            totalRecipientFeesMath: 0,
        }
    },
    methods: {
        doFontSizeComputed () {
            this.fontSizeNormalInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormal, 16);
            this.fontSizeNormalInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormalLineHeight, 20);
            this.fontSizeRecordInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeRecord, 16);
            this.fontSizeRecordInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeRecordLineHeight, 17);
        },
        goBack(){
            this.userData.selectedPaymentObject = undefined;
            this.userData.nextScreen = this.userData.fromScreen;
            // console.log("this.userData.nextScreen: " + this.userData.nextScreen);
            this.$emit("next-action", this.userData);
        },
        doShowTipFeesInfo(){
            // this.userData.fromScreen = "tipInfoScreen";
            this.userData.nextScreen = "showTipFeesInfoRecipient";
            this.userData.selectedPaymentObject = this.payment;
            Parse.Cloud.run("sendEmail", {
                toEmail: "appnotif@thanku.app", 
                subject: "ALERT! Recipient " + this.userData.displayname + " viewed tip FEES info from " + this.userData.fromScreen,
                body: "",
            });
            this.$emit("next-action", this.userData);
        },
        async getPaymentInfo() {
            try {

                console.log("this.userData.selectedPaymentObjectId:: " + this.userData.selectedPaymentObjectId);
                const payment = await Parse.Cloud.run("getTipInfo", { paymentObjectId: this.userData.selectedPaymentObjectId, });  
                payment.tipperNameUppercase = payment.tipperName.toUpperCase();
                this.payment = payment;
                // console.log("this.payment:: " + JSON.stringify(this.payment, null, 2));
                this.userData.selectedPaymentObject = payment;
                this.totalRecipientFeesMath = this.payment.totalRecipientFeesMath;
                return;
            } catch (e) {
                console.error(e);
                throw e;
            } 
        },
    },
    created(){

        Parse.serverURL = 'https://parseapi.back4app.com/'; Parse.initialize(this.appId, this.appJSKey); (this.appId, this.appJSKey);

        if (this.userData.selectedPaymentObject !== undefined && this.userData.selectedPaymentObject.transactionId !== ""){
            // we already have the payment data
            this.payment = this.userData.selectedPaymentObject;
            this.totalRecipientFeesMath = this.payment.totalRecipientFeesMath;

        } else {
            this.getPaymentInfo();
        }
    },
    mounted(){
        this.doFontSizeComputed();
        this.vhMult = shared.setScreenHeightMultiplier();
        shared.saveToUserPath(this.devEnv, "Tip info " + new Date().toISOString(), this.globalPushForwardInterval);
    }
}
</script>

<style scoped>
.table {
  height:4vh;
  display: grid;
  /* outline:0.01em solid black; */
  grid-template-columns: auto auto auto;
}

.cell {
  text-overflow: clip;
  /* color: #bdc2ca; */
  overflow: hidden;
  white-space: nowrap;
  display: flex;
  justify-content: left;
  align-items: center;
  /* outline:0.01em solid black; */
  
}

</style>