<template>
    <div @click="$emit('payout-selected', this.payoutObjectId, this.payoutDate, this.payoutBasis)" class="wrapper-todiv"  style="cursor:pointer;">
        <div class="userinfoframe" style="height: 6vh">
            <table align=left cellpadding=0 style="height:30%;" border=0 width=100%>
            
                <tr>

                    <td align=left width=40% class="lt-blue divl" style="height: 1vh; letter-spacing: -0.41px" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                        {{ this.showProgress }} {{ this.payoutDate }} <br/>
                        <!-- <span style="font-size: 10px">{{this.payoutObjectId}}</span> -->
                    </td>
                    
                    <td align=center width=30%  :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                        <span v-if="this.payoutStatusLocal !== 'failed'" style="color:#718096; padding-right:2vh" > {{ this.payoutStatusLocal }}</span>
                        <span v-if="this.payoutStatusLocal === 'failed'" style="color:#FEB2B2; padding-right:2vh" > {{ this.payoutStatusLocal }}</span>
                    </td>
                    <td align=right width=20% class="lt-blue divr" style="height: 1vh; letter-spacing: -0.41px" :style="{ 'font-size': fontSizeRecordInPXToUse + 'px', 'line-height': fontSizeRecordInPXLineHeightToUse + 'px', }">
                        <span style="color: #68D391">{{ this.payoutOperator }}</span>&nbsp;{{ this.payoutAmountReceived }} 
                    </td>
                    <td align=right width=10% >
                         <img class="tulogo" valign=middle style="height: 1.8vh; padding-bottom:0.3vh" alt="back" src="../assets/chevronright.png">
                    </td>
                    
                </tr>
           
            </table>
           
        </div>
        <hr>
    </div>
</template>

<script>

import shared from '../shared.js';

export default {
    name: 'PayoutRecord',
    props:  {
        payoutObjectId: String,
        payoutAmountReceived: String,
        payoutStatus: String,
        payoutDate: String,
        payoutOperator: String,
        payoutDateInMillis: Date,
        payoutBasis: String,
    },
    data (){
        return {
            fontSizeNormal: 2.5, // vh
            fontSizeNormalInPXToUse: "",
            fontSizeNormalLineHeight: 3.3,// vh
            fontSizeNormalInPXLineHeightToUse: "",
            fontSizeRecord: 2.5, // vh
            fontSizeRecordInPXToUse: "",
            fontSizeRecordLineHeight: 2.7,// vh
            fontSizeRecordInPXLineHeightToUse: "",
            isRecipient: false,
            showProgress: ".",
            payoutStatusLocal: "",
        }
    },
    methods: {
        doFontSizeComputed () {
        
            this.fontSizeNormalInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormal, 16);
            this.fontSizeNormalInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeNormalLineHeight, 20);
            this.fontSizeRecordInPXToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeRecord, 16);
            this.fontSizeRecordInPXLineHeightToUse = shared.convertVHSizeToMaxPixelsSize(this.fontSizeRecordLineHeight, 17);
        },
        doShowProgress(){
            if (this.payoutAmountReceived === undefined) {
                if (this.showProgress === "...") {
                    this.showProgress = "";
                } else {
                    this.showProgress += ".";
                }
                window.setTimeout(this.doShowProgress, 200); 
            } else {
                this.showProgress = "";
            }
        },
    },
    mounted() {

        // console.log("this.payoutDateInMillis: " + this.payoutDateInMillis);

        // console.log("this.payoutDate: " + this.payoutDate);
 
        if (this.payoutStatus === ""){
            // console.log("PAYOUT DATE: " + this.dateInMillis + " new Date(this.payoutDate).getTime(): " + new Date(this.dateInMillis).getTime() + "    new Date().getTime(): " + new Date().getTime());
            if (new Date(this.payoutDateInMillis).getTime() > new Date().getTime()){
                this.payoutStatusLocal = "pending";
            } else {
                this.payoutStatusLocal = "paid";
            }
        } else {
            this.payoutStatusLocal = this.payoutStatus;
        }
         
        this.doShowProgress();
        this.doFontSizeComputed();
        if (window.localStorage.getItem("tuisrecipient") !== null){
            this.isRecipient = window.localStorage.getItem("tuisrecipient");
        }
    }
} 
</script> 

<style scoped>

    hr {
        border-width: 0.01px 0 0 0;
        border-style: single;
        border-color:  #E2E8F0;
        margin: 0px auto;
    }

</style>